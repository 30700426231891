import React, { useState } from 'react'
import axios from 'axios'
import Editor from 'react-simple-code-editor'
import domtoimage from 'dom-to-image-more'
import QRCode from 'qrcode.react'
import { FormGroup, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faJs, faCss3Alt, faHtml5, faPython, faJava, faPhp } from '@fortawesome/free-brands-svg-icons'
import { faTools, faImage, faAngleLeft, faAngleRight, faCog } from '@fortawesome/free-solid-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'

import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-css'
// import 'prismjs/components/prism-python'
import 'prismjs/components/prism-markup-templating.js'
// import 'prismjs/components/prism-php'
// import 'prismjs/components/prism-java'

import 'bootstrap/dist/css/bootstrap.css'
import '../prism/css/dracula-prism.css'
import '../prism/css/base16-ateliersulphurpool-light-prism.css'
import '../prism/css/github-prism.css'
import '../prism/css/atom-dark-prism.css'
import '../prism/css/duotone-sea-prism.css'
import logo from '../images/code_is_art-large-white-logo.png'
import { browserCheck } from '../pages';

config.autoAddCss = false

export default function PosterCreator() {
  const defaultState = {
    language: '',
    languageIcon: '',
    prismLanguage: '',
    previewPaneVisible: false,
    themeClassName: 'dracula-prism',
    qrForegroundColor: 'rgba(98, 114, 164, 1)',
    title: '',
    qrValue: '',
    code: '',
    editorHorizontalCutoff: false,
    editorVerticalCutoff: false,
    posterImage: '',
    submitting: false,
    submittingStep: 1,
    submittingMessage: 'Generating poster...',
    formattingVerified: false
  };

  const [posterConfigState, setPosterConfigState] = useState(defaultState);

  return (
    <div>
      { posterConfigState.submitting && 
        <div className="Site--submitting-overlay">
          <p>
            <FontAwesomeIcon className="Site--submitting-icon" icon={faCog} spin size="2x" />
            Step {posterConfigState.submittingStep} of 2: {posterConfigState.submittingMessage}
          </p>
        </div>
      }
      <h2><FontAwesomeIcon icon={faTools} /> Create Your Own Code Poster</h2>
      <ul className="Poster--wizard-steps">
        <li>
          <span>Step 1</span> Choose Programming Language
          <div className="Poster--wizard-input">
            <FormGroup>
              <Input 
                type="select" 
                value={posterConfigState.language} 
                onChange={(evt) => {
                  const value = evt.target.value;
                  let icon = '';
                  let prismLang = '';
                  let code = '';
                  let previewPaneVisible = false;
                  switch(value) {
                    case 'javascript':
                      icon = faJs;
                      prismLang = languages.js;
                      code = '';
                      previewPaneVisible = true;
                      break;
                    case 'html':
                      icon = faHtml5;
                      prismLang = languages.markup;
                      code = '';
                      previewPaneVisible = true;
                      break;
                    case 'css':
                      icon = faCss3Alt;
                      prismLang = languages.css;
                      code = '';
                      previewPaneVisible = true;
                      break;
                    // case 'python':
                    //   icon = faPython;
                    //   prismLang = languages.python;
                    //   code = '';
                    //   previewPaneVisible = true;
                    //   break;
                    // case 'php':
                    //   icon = faPhp;
                    //   prismLang = languages.php;
                    //   code = '';
                    //   previewPaneVisible = true;
                    //   break;
                    // case 'java':
                    //   icon = faJava;
                    //   prismLang = languages.java;
                    //   code = '';
                    //   previewPaneVisible = true;
                    //   break;
                    default:
                      icon = '';
                      prismLang = '';
                      code = '';
                      previewPaneVisible = false;
                  }
                  setPosterConfigState(prevState => {
                    return { 
                      ...prevState, 
                      language: value, 
                      languageIcon: icon, 
                      prismLanguage: prismLang,
                      code: code,
                      previewPaneVisible: previewPaneVisible
                    }
                  });
                }}>
                <option value="">Choose a language...</option>
                <option value="javascript">JavaScript</option>
                <option value="html">HTML</option>
                <option value="css">CSS</option>
                {/* <option value="python">Python</option>
                <option value="php">PHP</option>
                <option value="java">Java</option> */}
              </Input>
            </FormGroup>
          </div>
        </li>
        <li>
          <span>Step 2</span> Choose Syntax Highlight Theme
          <div className="Poster--wizard-input">
            <FormGroup>
            <Input 
                type="select" 
                value={posterConfigState.themeClassName} 
                onChange={(evt) => {
                  let themeClassName = evt.target.value;
                  let qrForegroundColor = '';

                  switch(themeClassName) {
                    case 'dracula-prism':
                      qrForegroundColor = 'rgba(98, 114, 164, 1)'; 
                      break;
                    case 'base16-ateliersulphurpool-light-prism':
                      qrForegroundColor = '#898ea4';
                      break;
                    case 'atom-dark-prism':
                      qrForegroundColor = '#7C7C7C';
                      break;  
                    case 'github-prism':
                      qrForegroundColor = '#4C4C4C';
                      break;
                    case 'duotone-sea-prism':
                      qrForegroundColor = '#4a5f78';
                      break;
                    default:
                      qrForegroundColor = 'rgba(98, 114, 164, 1)'; 
                  }
                  setPosterConfigState(prevState => {
                    return { 
                      ...prevState, 
                      themeClassName: themeClassName,
                      qrForegroundColor: qrForegroundColor
                    }
                  });
                }}>
                <option value="dracula-prism">Dracula</option>
                <option value="github-prism">GitHub</option>
                <option value="atom-dark-prism">Atom Dark</option>
                <option value="base16-ateliersulphurpool-light-prism">Base16 Ateliersulphurpool Light</option>
                <option value="duotone-sea-prism">Duotone Sea</option>
              </Input>
            </FormGroup>
          </div>
        </li>
        <li>
          <span>Step 3</span> Set Poster Title
          <div className="Poster--wizard-input">
            <FormGroup>
              <Input 
                type="text" 
                onChange={(evt) => {
                  const value = evt.target.value;
                  setPosterConfigState(prevState => {
                    return { ...prevState, title: value }
                  });
                }} 
                value={posterConfigState.title}
                placeholder="Enter a poster title..." 
              />
            </FormGroup>
          </div>
        </li>
        <li>
          <span>Step 4</span> Set QR Code Destination URL
          <div className="Poster--wizard-input">
            <FormGroup>
              <Input 
                type="text" 
                onChange={(evt) => {
                  const value = evt.target.value;
                  setPosterConfigState(prevState => {
                    return { ...prevState, qrValue: value }
                  });
                }}
                value={posterConfigState.qrValue}
                placeholder="Enter a QR code destination URL..." 
              />
            </FormGroup>
          </div>
        </li>
        <li>
          <span>Step 5</span> Add Your Custom Code
        </li>
      </ul>
      <div className={`${posterConfigState.themeClassName} App`}>
        <EditorComponent 
          posterConfigState={posterConfigState}
          setPosterConfigState={setPosterConfigState}
          defaultState={defaultState}
        />
      </div>
    </div>
  )
}

export function EditorComponent(props) {

  let browserWidthCheck = 0;
  if (browserCheck().isChrome) {
    browserWidthCheck = 544;
  } else if (browserCheck().isFirefox) {
    browserWidthCheck = 545;
  }

  let browserHeightCheck = 0;
  if (browserCheck().isChrome) {
    browserHeightCheck = 584;
  } else if (browserCheck().isFirefox) {
    browserHeightCheck = 596;
  }

  function generateImage(quality) {
    props.setPosterConfigState(prevState => {
      return { 
        ...prevState, 
        submitting: true,
        submittingStep: props.defaultState.submittingStep,
        submittingMessage: props.defaultState.submittingMessage
      }
    });
    /* create the image from DOM element */
    domtoimage.toJpeg(document.getElementById('App--Poster'), {quality: quality, cacheBust: true}).then(function (dataUrl) {
      
      props.setPosterConfigState(prevState => {
        return { 
          ...prevState,
          submittingStep: 2,
          submittingMessage: 'Downloading High Resolution File...'
        }
      });

      setTimeout(() => {
        let link = document.createElement('a');
      
        link.download = 'my-codeisart-image.jpeg';
        link.href = dataUrl;
        link.click();

        /* reset react component to the default state */
        props.setPosterConfigState(prevState => {
          return {
            ...prevState, 
            submitting: false,
            formattingVerified: true
          }
        });
      }, 3000);
    }).catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }

  return (
    <div>
      <div className="App--EditorComponentWrap">
        <div id="App--EditorComponentEl" className="App--EditorComponent">
          
          { (props.posterConfigState.editorHorizontalCutoff || props.posterConfigState.editorVerticalCutoff) &&
            <div className="App--OverflowWarningOverlay" style={{maxWidth: browserWidthCheck + 'px', maxHeight: (browserHeightCheck - 4) + 'px'}}></div>
          }

          <Editor
            id="App--MainEditor"
            value={props.posterConfigState.code}
            onValueChange={code => props.setPosterConfigState(prevState => {
              return { ...prevState, code: code}
            })}
            disabled={props.posterConfigState.language ? false : true}
            placeholder={props.posterConfigState.language ? `Type some code...` : `Choose a language...`}
            onKeyUp={(evt) => {
              // detect if code flows outside of boundaries
              const editComponentEl = document.getElementById('App--EditorComponentEl');
              const elWidth = editComponentEl.offsetWidth;
              const elHeight = editComponentEl.offsetHeight;
              
              if (elWidth > browserWidthCheck) {
                props.setPosterConfigState(prevState => {
                  return { ...prevState, editorHorizontalCutoff: true}
                });
              } else {
                props.setPosterConfigState(prevState => {
                  return { ...prevState, editorHorizontalCutoff: false}
                });
              }

              if (elHeight > browserHeightCheck) {
                props.setPosterConfigState(prevState => {
                  return { ...prevState, editorVerticalCutoff: true}
                });
              } else {
                props.setPosterConfigState(prevState => {
                  return { ...prevState, editorVerticalCutoff: false}
                });
              }
            }}
            highlight={code => highlight(code, props.posterConfigState.prismLanguage)}
            padding={5}
            tabSize={2}
            insertSpaces={true}
            style={{
              fontSize: 11,
              lineHeight: 1.3,
              fontWeight: 500,
              minWidth: props.posterConfigState.code ? 'auto' : 160
            }}
          />

          { (props.posterConfigState.editorHorizontalCutoff || props.posterConfigState.editorVerticalCutoff) &&  
            <p className="App--WidthOverflowWarning">
              WARNING! Some of your code is outside of the safe printable area.<br />
              For best results, keep all of your code within the safe zone.
            </p>
          }

        </div>
      </div>

      <div id="App--PosterPositionWrap" className={(props.posterConfigState.language && props.posterConfigState.previewPaneVisible) ? 'slideIn' : ''}>
        <div id="App--PosterPositionInner">
          <div className={`App--PosterPreviewToggler ${!props.posterConfigState.language ? 'invisible' : ''}`} onClick={() => {
            props.setPosterConfigState(prevState => {
              return { ...prevState, previewPaneVisible: !props.posterConfigState.previewPaneVisible}
            });
          }}>
            <FontAwesomeIcon icon={props.posterConfigState.previewPaneVisible ? faAngleRight : faAngleLeft} />
          </div>
          <h2><FontAwesomeIcon icon={faImage} /> Poster Preview</h2>
          <div className="App--PosterBorder">
            <div id="App--PosterPosition">
              <div id="App--Poster" className="App--PosterComponent">
                <div className="App--PosterTitle">
                  { props.posterConfigState.qrValue.length === 0 && 
                    <h1>{props.posterConfigState.title}</h1>
                  }
                  { props.posterConfigState.qrValue.length > 0 &&
                    <h1 className="App--TitleHasQrCode">{props.posterConfigState.title}</h1>
                  }
                </div>
                { !props.posterConfigState.qrValue &&
                  <div className="App--QrCodeWrap" />
                }
                { props.posterConfigState.languageIcon &&
                  <FontAwesomeIcon 
                    className="App--PosterLanguageIcon" 
                    icon={props.posterConfigState.languageIcon} 
                  />
                }
                <Editor
                  id="App--PosterEditorElement"
                  className="App--PosterCode"
                  value={props.posterConfigState.code}
                  onValueChange={code => props.setPosterConfigState({code})}
                  highlight={code => highlight(code, props.posterConfigState.prismLanguage)}
                  padding={5}
                  tabSize={2}
                  insertSpaces={true}
                  style={{
                    fontSize: 98,
                    lineHeight: 1.3,
                    fontWeight: 500,
                    maxHeight: 5200
                  }}
                />
                <div className="App--PosterDetails">
                  { props.posterConfigState.qrValue && 
                    <div className="App--QrCodeWrap">
                      <QRCode 
                        value={props.posterConfigState.qrValue} 
                        className="App--QrCode"
                        size={400}
                        bgColor={'rgba(0, 0, 0, 0)'}
                        fgColor={props.posterConfigState.qrForegroundColor}
                      />
                    </div>
                  }
                </div>
                <img className="App--PosterLogo" src={logo} alt="Code is Art Logo" />
              </div>
            </div>
          </div>

          { !props.posterConfigState.submitting && 
            <div>
              <button className="App--BuyPosterBtn" onClick={() => {
                generateImage(1.0);
              }}>Download High Resolution File</button>
            </div>
          }

          { props.posterConfigState.submitting && 
            <div className="App--GeneratingPosterMsg">
              <h5>Generating Poster...</h5>
            </div>
          }
        </div>
      </div>
    </div>
  )
}