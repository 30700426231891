import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faLaptopCode, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import ReactImageMagnify from 'react-image-magnify'
import { isMobileOrTablet } from '../pages';

// import sidebarImage from'../images/es6-class-implementation_poster.jpg'
import sidebarImage from'../images/cia_1_cropped_2.png'

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileOrTablet: false
    };
  }
  componentDidMount() {
    this.setState({
      isMobileOrTablet: isMobileOrTablet()
    });
  }
  render() {
    return (
      <div className="Site--sidebar">
        <div className="Site--sidebar-info">
          <h2><FontAwesomeIcon icon={ faInfoCircle } /> How is Code Art?</h2>
          <p>Writing code can be a very creative outlet. <strong>Code Is Art</strong> allows anyone with an appreciation for elegant code -- to take that passion and convert it into a high resolution (18" x 24" and 12" x 16") image file that can be printed and displayed as a beautiful piece of art...</p>
          <p>...because <strong>beautiful code</strong> deserves wall space.</p>
        </div>
        { !this.state.isMobileOrTablet && 
            <div className="Site--sidebar-image">
              <ReactImageMagnify {...{
                smallImage: {
                  alt: 'Poster Image Example',
                  isFluidWidth: false,
                  width: 378,
                  height: 468,
                  src: sidebarImage
                },
                largeImage: {
                  src: sidebarImage,
                  width: 2000,
                  height: 2475
                },
                enlargedImageContainerClassName: 'Site--sidebar-enlarged-image-container',
                enlargedImageClassName: 'Site--sidebar-enlarged-image',
                enlargedImagePosition: 'over',
                isHintEnabled: true,
                enlargedImageContainerDimensions: {
                  width: '100%',
                  height: '100%'
                }
              }} />
            </div>
        }
        { this.state.isMobileOrTablet && 
          <div>
            <img className="Site--sidebar-image" src={sidebarImage} alt="Poster Image Example" />
            <div className="Site--sidebar-mobile-message">
              <h2><FontAwesomeIcon icon={faExclamationTriangle} /> Mobile not supported.</h2>
              <p>This site has an embedded code editor that is used to generate a custom image that can be printed as a poster.</p>
              <p>Since writing code on a mobile device is not easy or ideal, we have disabled the ability to enter code and create a poster in mobile browsers.</p>
              <p>Click the link below to send yourself a reminder to visit <strong>CodeIsArt</strong> the next time you’re at your desktop computer.</p>
              <a className="Site--RemindMeBtn" href="mailto:?subject=Check%20out%20CodeIsArt&body=Just%20a%20small%20reminder%20to%20check%20out%20CodeIsArt%20at%20https%3A%2F%2Fcodeisart.tech">Send Myself A Reminder</a>
            </div>
          </div>
        }
        <div className="Site--sidebar-languages">
          <h2><FontAwesomeIcon icon={faLaptopCode} /> Supported Languages</h2>
          <p>You can currently generate high (print) resolution files, with proper syntax highlighting, for the following technologies...</p>
          <ul>
            <li>JavaScript</li>
            <li>CSS</li>
            <li>HTML</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar